import React, { useState, useEffect, useRef } from 'react';
import styled from "@emotion/styled";
import { Link, useParams, useLoaderData, useNavigate  } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

const SGrayBar = styled.div`
    width: 100%;
    height: 44px;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0px;
    background: #D8D8D8;
    font-size: 15px;
    .widthNotSaved,
    .visiliblityHidden {
        visibility: hidden;
    }
    .widthSaved {
        visibility: show;
    }
    .hide {
        display: none;
    }
    .disabled {
        pointer-events: none;
    }

    .hide850 {
        display: none;
        @media screen and ( min-width: 850px ) {
            display: unset;
        }
    }

    @media screen and ( min-width: 850px ) {
        .close-icon-mobile-only {
            display: none;
        }
    }
`

const GrayMenu = styled.div`
 //   width: calc( 100% - 32px );
    width: calc( 100% );
 //   width: calc( 100vw - 32px );
    height: 44px;
    position: fixed;
    top: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 16px;
    padding-right: 16px; */
    > div {
        display: flex;
        text-wrap: nowrap;
        align-items: center;
    }

    .close-icon{
        padding: 4px;
        svg {
            width: 32px;
            height: 32px;
        }
    }
`

const GrayMenuTitle = styled.div`
    width: 100%;
    height: 44px;
    position: fixed;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-content: center;
`

const CountWidths = styled.div`
    overflow: hidden;
    text-wrap: nowrap;
    width: 1px;
    height: 1px;
    z-index: -1;
`


async function scrollUp() {
    return await new Promise(resolve => {
        const scrollY = window.scrollY;
        if(scrollY < 10) resolve('foo');
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        const interval = setInterval(() => {
            if (window.scrollY === 0) {
            // if (window.scrollY < scrollY / 2) {
                resolve('foo');
                clearInterval(interval);
            };
        }, 50);
    });
}


function GrayBar(props) {
    const navigate = useNavigate();
    const backwardsRef = useRef(null);
    const forwardsRef = useRef(null);
    const titleRef = useRef(null);
    const { backwardsId, forwardsId, backwardsTitle, forwardsTitle, title, windowWidth } = props;

  //  const [showTitle, set_showTitle] = useState(false);
    const [showBackwardsAndForwards, set_showBackwardsAndForwards] = useState(false);


    const backwardWidth = backwardsRef?.current?.offsetWidth;
    const forwardsWidth = forwardsRef?.current?.offsetWidth;
    const titleWidth = titleRef?.current?.offsetWidth;

    const [backwardWidthSaved, set_backwardWidthSaved] = useState(0);
    const [forwardsWidthSaved, set_forwardsWidthSaved] = useState(0);
    // const [titleWidthSaved, set_titleWidthSaved] = useState(0);

    // useEffect(() => {
    //     if(backwardsTitle && backwardWidth) {
    //         console.log('backwardWidth', backwardWidth)
    //         set_backwardWidthSaved(backwardWidth);
    //     } else if (!backwardsTitle) {
    //         set_backwardWidthSaved(0);
    //     }
    // },[backwardsTitle, backwardWidth]);

    // useEffect(() => {
    //     if(forwardsTitle && forwardsWidth) {
    //         set_forwardsWidthSaved(forwardsWidth);
    //         console.log('forwardsWidth', forwardsWidth);
    //     } else if (!forwardsTitle) {
    //         set_forwardsWidthSaved(0);
    //     }
    // },[forwardsTitle, forwardsWidth]);

    // useEffect(() => {
    //     console.log({title, titleWidth})
    //     if(titleWidth) set_titleWidthSaved(titleWidth);
    // },[title, titleWidth]);

    // useEffect(() => {
    //     console.log({backwardWidthSaved, forwardsWidthSaved, windowWidth})
    //     if(!backwardWidthSaved || !forwardsWidthSaved || !windowWidth) return;
    //     const longer = backwardWidthSaved < forwardsWidthSaved ? forwardsWidthSaved : backwardWidthSaved;
        // if(windowWidth - (16 + 40 + 80 + 16 + longer + longer + 128) < 0){
        //     set_showTitle(false);
        // } else {
        //     set_showTitle(true);
        // }
    //    console.log({windowWidth, addition2: (16 + 40 + 80 + 16 + longer + longer + 128), add: (16 + 40 + 80 + 16 + longer + longer + titleWidthSaved + 128)})
    //     if(windowWidth - (16 + 40 + 80 + 16 + longer + longer + 96) < 0){
    //         set_showBackwardsAndForwards(false);
    //     } else {
    //         set_showBackwardsAndForwards(true);
    //     }
    // },[
    //     backwardWidthSaved,
    //     forwardsWidthSaved,
    //     windowWidth
    // ]);


    return (
        <SGrayBar
            className={`GrayBar `}
        >
            {/* <GrayMenuTitle
                className={[
                    showTitle ? '' : 'hide'
                ].join(' ')}
            >
                <span
                >
                    {title}
                </span>
            </GrayMenuTitle> */}
            <GrayMenu>
                <div
                 //   style={backwardsId === null ? {visibility: 'hidden'} : {}}
                    className={[
                        backwardsId === null ? 'visiliblityHidden' : ''
                    ].join(' ')}
                >
                    <IconButton
                        aria-label="back"
                        disabled={backwardsId===null || props.movingAnimation}
                        onClick={async ()=>{
                        //    navigate(`/chapter/${backwardsId}`);
                        //    window.scrollTo(0, 0);
                            await scrollUp();
                            props.moveLeft(backwardsId);
                        }}
                    >
                        <ArrowForwardIosIcon style={{rotate: '180deg'}}/>
                    </IconButton>
                    <span
                        className={[
                //            showBackwardsAndForwards ? '' : 'hide'
                            'hide850'
                        ].join(' ')}
                    >
                        {backwardsTitle}
                    </span>
                </div>
                <Link
                    className={[
            //            showBackwardsAndForwards ? 'hide' : '',
                        props.movingAnimation ? 'disabled' : '',
                        'close-icon-mobile-only'
                    ].join(' ')}
                    to={`/`}
                    disabled={props.movingAnimation}
                >
                    <IconButton
                        aria-label="forward"
                        className='close-icon'
                        disabled={props.movingAnimation}
                    >
                        <CloseIcon />
                    </IconButton>
                </Link>
                <div>
                    <span
                        className={[
                    //        showBackwardsAndForwards ? '' : 'hide'
                            'hide850'
                        ].join(' ')}
                    >
                        {forwardsTitle}
                    </span>
                    <IconButton
                        aria-label="forward"
                        disabled={forwardsId===null || props.movingAnimation}
                        onClick={async()=>{
                        //    navigate(`/chapter/${forwardsId}`);
                        //   window.scrollTo(0, 0);
                            await scrollUp();
                            props.moveRight(forwardsId);
                        }}
                        className={[
                            forwardsId === null ? 'visiliblityHidden' : ''
                        ].join(' ')}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                    <Link
                        className={[
                //            showBackwardsAndForwards ? '' : 'hide'
                            'hide850'
                        ].join(' ')}
                        to={`/`}
                        disabled={props.movingAnimation}
                        onClick={()=>{
                            window.scrollTo(0, 0);
                        }}
                    >
                        <IconButton
                            aria-label="forward"
                            className='close-icon'
                        >
                            <CloseIcon />
                        </IconButton>
                    </Link>
                </div>
            </GrayMenu>
            <CountWidths>
                <span
                    ref={forwardsRef}
                >
                    {forwardsTitle}
                </span>
                <span
                    ref={backwardsRef}
                >
                    {backwardsTitle}
                </span>
                <span
                    ref={titleRef}
                >
                    {title}
                </span>
            </CountWidths>
        </SGrayBar>
    );
  }

  export default GrayBar;
