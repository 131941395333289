import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import styled from "@emotion/styled";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

const Container = styled.div`
    min-height: 70px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
`
const Yellow = styled.div`
    background-color: #EAE8E8;
    padding: 16px 48px;
    border-radius: 14px;
    max-width: 672px;
    width: calc( 100% - 48px - 48px );

    .slick-slider{
        height: 100%;
        .slick-next,
        .slick-prev {
            width: 24px;
            height: 24px;
        }
        .slick-prev:before{
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' data-testid='ArrowForwardIosIcon' style='rotate: 180deg; fill: rgb(99 99 99);'%3E%3Cpath d='M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z'%3E%3C/path%3E%3C/svg%3E")
        }
        .slick-next:before{
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' data-testid='ArrowForwardIosIcon' style='fill: rgb(99 99 99);' %3E%3Cpath d='M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z'%3E%3C/path%3E%3C/svg%3E")
        }
        .slick-next.slick-disabled:before,
        .slick-prev.slick-disabled:before {
            opacity: 0;
            cursor: default;
        }
        .slick-list{
            height: calc( 100% - 20px );
            .slick-track{
                height: 100%;
                .slick-slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .slick-dots{
            top: 7px;
            li > button {
                width: 15px;
                height: 15px;
                border: rgb(99 99 99) solid 2px;
                border-radius: 32px;
                &:hover {
                    background: white;
                }
                :before {
                    content: unset;
                }
            }
            li.slick-active > button {
                background: rgb(99 99 99);;
                
            }
        }
    }
`

const Paragraph = styled.h3`
    font-size: 15px;    
    text-align: center;
`

function YellowBanner(props) {

    const multipleContent = //false;
        props.yellowBanner.multipleContent?.message_1 !== '' || 
        props.yellowBanner.multipleContent?.message_2 !== '' || 
        props.yellowBanner.multipleContent?.message_3 !== '' || 
        props.yellowBanner.multipleContent?.message_4 !== '';

    console.log('multipleConetent', multipleContent, props)

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => {
          return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
        }
    };

    return (
        <Container 
            className={`YellowBanner `}
        >
            {    
                props.yellowBanner.show === 'On' && 
                <Yellow>
                    {
                    multipleContent ?
                        <Slider {...settings}>
                            <Paragraph
                                dangerouslySetInnerHTML={{__html: props.yellowBanner.content}}
                            />
                            {
                                props.yellowBanner.multipleContent?.message_2 !== '' &&
                                <Paragraph
                                    dangerouslySetInnerHTML={{__html: props.yellowBanner.multipleContent?.message_2}}
                                />
                            }
                            {
                                props.yellowBanner.multipleContent?.message_3 !== '' &&
                                <Paragraph
                                    dangerouslySetInnerHTML={{__html: props.yellowBanner.multipleContent?.message_3}}
                                />
                            }
                            {
                                props.yellowBanner.multipleContent?.message_4 !== '' &&
                                <Paragraph
                                    dangerouslySetInnerHTML={{__html: props.yellowBanner.multipleContent?.message_4}}
                                />
                            }
                        </Slider>
                        :
                        <Paragraph
                            dangerouslySetInnerHTML={{__html: props.yellowBanner.content}}
                        />
                    }
                </Yellow>
            }
        </Container>
    );
}

export default YellowBanner;
  