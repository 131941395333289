import React, { useState, useEffect } from 'react';
import { WP_API_URL } from '../../config';
import styled from "@emotion/styled";
import Thumbnail from './thumbnail'

const Container = styled.div`
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    justify-items: center;

    margin: auto;
    max-width: 768px;
    font-size: 17px; */

    margin: auto;
    max-width: 1200px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;

    @media screen and (max-width: 600px){
        grid-template-columns: 100%;
    }
`


function Chapters(props) {
    return (
        <Container
            className={`ChaptersSection `}
        >
            {
                props.posts && props.posts.map(post =>
                    <Thumbnail
                        imageUrl={
                            post._embedded["wp:featuredmedia"] &&
                            post._embedded["wp:featuredmedia"].length > 0 && post._embedded["wp:featuredmedia"][0].source_url
                        }
                        title={post.title.rendered}
                        postId={post.id}
                        key={`thumbnail-${post.id}`}
                    />
                )
            }
        </Container>
    );
  }

  export default Chapters;
