import React, { useState, useEffect, useRef } from 'react';
import styled from "@emotion/styled";
import ReactPlayer from "react-player";

const Container = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    .float-possible{
        display: flex;
        flex-direction: row;
        max-width: 1400px;
        width: 100%;
        justify-content: center;
    }
    @media screen and ( min-width: 701px ) {
        margin-top: 100px;
        margin-bottom: 100px;
        .float-possible{
            margin-left: 16px;
            margin-right: 16px;
            &.float-right {
                justify-content: flex-end;
            }
            &.float-left {
                justify-content: flex-start;
            }
            &.float-center {
                justify-content: center;
            }
        }
    }




    .ChapterMediaTitle {
        font-size: 15px;
        text-align: center;
        /* visibility: hidden;
        opacity:0;
        transition: visibility 0.3s linear,opacity 0.3s linear;
        &.visible {
            visibility: visible;
            opacity:1;
        } */
    }


    .animate {
        position: relative;
        left: -100vw;
    }

    .run-animation {
        animation:  image-slide-in-1s 1000ms forwards;
        animation-delay: 100ms;
    }

    @keyframes image-slide-in-1s {
        100% {
            left: 0;
        }
    }
`

const Image = styled.img`
    width: 100%;
    max-width: 455px;
    border-radius: 18px;
    @media screen and ( min-width: 701px ) {
        /* height: 350px; */
        width: 65vw;
        max-width: 1080px;
        object-fit: cover;
        &.isVertical{
            max-width: 768px;
        }
    }
`

const VideoContainer = styled.div`
    width: 100%;
    max-width: 455px;
    @media screen and ( min-width: 701px ) {
        /* height: 350px;
        width: 555px; */
        width: 65vw;
        max-width: 1080px;
        &.isVertical{
            max-width: 768px;
        }
    }

`

const SReactPlayer = styled(ReactPlayer)`
    width: 100%!important;
    height: 100%!important;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 18px;
    }
`

function ChapterMedia(props) {
    const ref = useRef(null);
    const aspectRatioRef = useRef(null);
    const [isShown, setIsShown] = useState(false);
    const [isVertical, set_isVertical] = useState(false);
    const [shiftByPixelsStarted, set_shiftByPixelsStarted] = useState(false);


    const offsetTopElement = ref?.current?.offsetTop;

    useEffect(() => {
        shift();
        handleIsVertical();
    });

    function shift(){
        if(window.pageYOffset === 0) return;
        if(!props.visibleChapter) return;
        if(shiftByPixelsStarted) return;
        const elementHeight = ref?.current?.offsetHeight;
        if(!elementHeight) return;
        const startShiftAtHeight = offsetTopElement;
        props.log && console.log('startShiftAtHeight', props.id, startShiftAtHeight)
        const stopShiftAtHeight = offsetTopElement + (elementHeight / 2);
        props.log && console.log('stopShiftAtHeight', props.id, stopShiftAtHeight)
        const offsetYAtBottomOfWindow = props.offsetY + props.windowHeight;
        props.log && console.log('offsetYAtBottomOfWindow', props.id, offsetYAtBottomOfWindow)
        if(startShiftAtHeight < offsetYAtBottomOfWindow) {
            set_shiftByPixelsStarted(true);
        }
    }

    function handleIsVertical(){
        const elementHeight = aspectRatioRef?.current?.offsetHeight;
        const elementWidth = aspectRatioRef?.current?.offsetWidth;
        if(!elementHeight || !elementWidth) return;

        if(elementHeight > elementWidth) {
            set_isVertical(true);
        }
    }

    return (
        <Container
            className={`ChapterMedia float-${props.attrfloat}`}
        >
            {/* <div className={`float-possible float-${props.attrfloat}`}> */}
            {/* {console.log(props)} */}
            <div
                className={`float-possible float-center`}
                ref={ref}
            >
                <div
                    className={`animate ${shiftByPixelsStarted ? 'run-animation' : ''}`}
                    ref={aspectRatioRef}
                >
                    {
                        props.attrtype === 'image' &&
                            <Image
                                className={`ChapterMediaImage ${isVertical ? 'isVertical' : ''}`}
                                src={props.attrurl}
                            />
                    }

                    {
                        props.attrtype === 'video' &&
                            <VideoContainer
                                className={`ChapterMediaVideo ${isVertical ? 'isVertical' : ''}`}
                            >
                                    <SReactPlayer
                                        url={props.attrurl}
                                        controls
                                        loop
                                        preload="auto"

                                        muted
                                        autoPlay
                                        playing
                                    />
                            </VideoContainer>
                    }

                    {
                        props.attrtitle  &&
                        <p
                            className={`ChapterMediaTitle ${isShown ? 'visible' : ''}`}
                            dangerouslySetInnerHTML={{ __html: props.attrtitle }}
                        />
                    }
                </div>
            </div>
        </Container>
    );
  }

  export default ChapterMedia;
