import React, { useState } from 'react';
import Morphing from '../../components/Morphing';
import Chapters from '../../components/Chapters'
import YellowBanner from '../../components/YellowBanner';
import MovingBackground from '../About/MovingBackground';
import * as d3 from "d3";

function Collaborations(props) {
  const [showIntro, set_showIntro] = useState(true);
  const [stopIntro, set_stopIntro] = useState(false);


  const startWebsite = () => {
    set_stopIntro(true);
  }

  return (
    <div className="Collaborations">

    </div>
  );
}

export default Collaborations;
