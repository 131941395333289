import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import ReactPlayer from "react-player";
import { WP_API_URL } from '../../config';
import ArtBoard03 from './Artboard03_A.svg'
import Chrome from './Chrome.svg'

const ANIMATION_LENGTH = 3000; //ms
const ANIMATION_MOVE = 1000; //ms

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    height: 100svh;
    position: fixed;
    overflow: hidden;
    background-size: cover;
    z-index: 2;
    transition: ${props=>props.transparentAnimationTime}ms ease-out;

    &.transparent {
       background-color: transparent;
    }

    @keyframes slide-in-right-3s-forwards {
        100% { right: 5vmin; }
    }

    @keyframes slide-out-right-3s-forwards {
        100% { right: 100vw; }
    }
`

const ContainerTransparent = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;

    &.stopIntro {
        animation-name: slide-container-out;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-delay: 0s;
    }

    @keyframes slide-container-out {
        100% { left: -100vw;  }
    }

`

const VideoContainer1 = styled.div`
    width: calc( 100vw - 10vmin );
    height: calc( ( 100vh - 15vmin ) / 2 );
    height: calc( ( 100svh - 15vmin ) / 2 );
    position: absolute;
    overflow: hidden;
    top: 5vmin;
    right: -100vw;
    border-radius: 40px;
    &.run-animation{
        animation-name: slide-in-right-3s-forwards, slide-out-right-3s-forwards;
        animation-duration: ${ANIMATION_MOVE}ms, ${ANIMATION_MOVE}ms;
        animation-fill-mode: forwards, forwards;
        animation-delay: ${0*ANIMATION_LENGTH}ms, ${1*ANIMATION_LENGTH}ms;
        &.disable_out_1n2 {
            animation-delay: 0s, 999s;
        }
    }

`

const VideoContainer2 = styled.div`
    width: calc( 100vw - 10vmin );
    height: calc( ( 100vh - 15vmin ) / 2 );
    height: calc( ( 100svh - 15vmin ) / 2 );
    position: absolute;
    bottom: 5vmin;
    overflow: hidden;
    right: -100vw;
    border-radius: 40px;
    &.run-animation{
        animation-name: slide-in-right-3s-forwards, slide-out-right-3s-forwards;
        animation-duration: ${ANIMATION_MOVE}ms, ${ANIMATION_MOVE}ms;
        animation-fill-mode: forwards, forwards;
        animation-delay: ${0*ANIMATION_LENGTH}ms, ${1*ANIMATION_LENGTH}ms;
        &.disable_out_1n2{
            animation-delay: 0s, 999s;
        }
    }
`

const VideoContainer3 = styled.div`
    width: calc( 100vw - 10vmin );
    height: calc( 100vh - 10vmin );
    height: calc( 100svh - 10vmin );
    position: absolute;
    overflow: hidden;
    right: -100vw;
    top: 5vmin;
    border-radius: 40px;
    &.run-animation {
        animation:  slide-in3 ${ANIMATION_MOVE}ms forwards,
                    slide-make-smaller3 ${ANIMATION_MOVE}ms forwards,
                    slide-out3 ${ANIMATION_MOVE}ms forwards;

        animation-delay: ${1*ANIMATION_LENGTH}ms, ${2*ANIMATION_LENGTH}ms, ${3*ANIMATION_LENGTH}ms;
        &.disable_out_1n2,
        &.disable_in_3 {
            animation-delay: 999s, 999s, 999s;
        }
    }

    @keyframes slide-in3 {
        100% {
            right: 5vmin;
        }
    }

    @keyframes slide-make-smaller3 {
        100% {
            height: calc( ( 100vh - 15vmin ) / 2 );
            height: calc( ( 100svh - 15vmin ) / 2 );
        }
    }

    @keyframes slide-out3 {
        100% {
            top: -100vh;
            top: -100svh;
        }
    }
`

const VideoContainer4 = styled.div`
    width: calc( ( 100vw - 15vmin ) / 2 );
    height: calc( ( 100vh - 15vmin ) / 2 );
    height: calc( ( 100svh - 15vmin ) / 2 );
    position: absolute;
    overflow: hidden;
    left: 5vmin;
    top: 100vh;
    top: 100svh;
    border-radius: 40px;
    &.run-animation {
        animation:  slide-in4 ${ANIMATION_MOVE}ms forwards,
                    slide-make-left-half4 ${ANIMATION_MOVE}ms forwards,
                    slide-make-left-top4 ${ANIMATION_MOVE}ms forwards,
                    slide-out4 ${ANIMATION_MOVE}ms forwards;

        animation-delay: ${2*ANIMATION_LENGTH}ms, ${3*ANIMATION_LENGTH}ms, ${4*ANIMATION_LENGTH}ms, ${6*ANIMATION_LENGTH}ms;
        &.disable_out_1n2,
        &.disable_in_3 {
            animation-delay: 999s, 999s, 999s;
        }
    }

    @keyframes slide-in4 {
        100% {
            top: calc( 50vh + 2.5vmin );
        }
    }

    @keyframes slide-make-left-half4 {
        100% {
            top: 5vmin;
            height: calc( 100vh - 10vmin );
            height: calc( 100svh - 10vmin );
        }
    }

    @keyframes slide-make-left-top4 {
        100% {
            height: calc( ( 100vh - 15vmin ) / 3 );
            height: calc( ( 100svh - 15vmin ) / 3 );
        }
    }

    @keyframes slide-out4 {
        100% {
            left: calc( ( 100vw - 15vmin ) / -2 );
        }
    }
`

const VideoContainer5 = styled.div`
    width: calc( ( 100vw - 15vmin ) / 2 );
    height: calc( ( 100vh - 15vmin ) / 2 );
    height: calc( ( 100svh - 15vmin ) / 2 );
    position: absolute;
    overflow: hidden;
    right: 5vmin;
    top: 100vh;
    top: 100svh;
    border-radius: 40px;
    &.run-animation {
        animation:  slide-in5 ${ANIMATION_MOVE}ms forwards,
                    slide-make-right-half5 ${ANIMATION_MOVE}ms forwards,
                    slide-make-right-top5 ${ANIMATION_MOVE}ms forwards,
                    slide-out5 ${ANIMATION_MOVE}ms forwards;

        animation-delay: ${2*ANIMATION_LENGTH}ms, ${3*ANIMATION_LENGTH}ms, ${4*ANIMATION_LENGTH}ms, ${5*ANIMATION_LENGTH}ms;
        &.disable_out_1n2,
        &.disable_in_3 {
            animation-delay: 999s, 999s, 999s;
        }
    }

    @keyframes slide-in5 {
        100% {
            top: calc( 50vh + 2.5vmin );
        }
    }

    @keyframes slide-make-right-half5 {
        100% {
            top: 5vmin;
            height: calc( 100vh - 10vmin );
            height: calc( 100svh - 10vmin );
        }
    }

    @keyframes slide-make-right-top5 {
        100% {
            height: calc( ( 100vh - 15vmin ) / 3 );
            height: calc( ( 100svh - 15vmin ) / 3 );
        }
    }

    @keyframes slide-out5 {
        100% {
            top: -100vh;
            top: -100svh;
        }
    }
`

const VideoContainer6 = styled.div`
    width: calc( 100vw - 10vmin );
    height: calc( ( 100vh - 15vmin ) / 3 * 2 );
    height: calc( ( 100svh - 15vmin ) / 3 * 2 );
    position: absolute;
    overflow: hidden;
    right: 5vmin;
    top: 100vh;
    top: 100svh;
    border-radius: 40px;
    &.run-animation {
        animation:  slide-in6 ${ANIMATION_MOVE}ms forwards,
                    slide-make-left6 ${ANIMATION_MOVE}ms forwards,
                    slide-out6 ${ANIMATION_MOVE}ms forwards;

        animation-delay: ${4*ANIMATION_LENGTH}ms, ${5*ANIMATION_LENGTH}ms, ${6*ANIMATION_LENGTH}ms;
        &.disable_out_1n2,
        &.disable_in_3 {
            animation-delay: 999s, 999s, 999s;
        }
    }

    @keyframes slide-in6 {
        100% {
            top: calc( ( 100vh - 15vmin ) / 3 + 10vmin );
            top: calc( ( 100svh - 15vmin ) / 3 + 10vmin );
        }
    }

    @keyframes slide-make-left6 {
        100% {
            width: calc( ( 100vw - 15vmin) / 2 );
            right: calc( 50vw + 2.5vmin );
        }
    }

    @keyframes slide-out6 {
        100% {
            right: 100vw;
        }
    }
`

const VideoContainer7 = styled.div`
    width: calc( ( 100vw - 15vmin) / 2 );
    height: calc( 100vh - 10vmin );
    height: calc( 100svh - 10vmin );
    position: absolute;
    overflow: hidden;
    right: -100vw;
    top: 5vmin;
    border-radius: 40px;
    &.run-animation {
        animation:  slide-in7 ${ANIMATION_MOVE}ms forwards,
                    slide-top-half7 ${ANIMATION_MOVE}ms forwards,
                    slide-out7 ${ANIMATION_MOVE}ms forwards;

        animation-delay: ${5*ANIMATION_LENGTH}ms, ${6*ANIMATION_LENGTH}ms, ${8*ANIMATION_LENGTH}ms;
        &.disable_out_1n2,
        &.disable_in_3 {
            animation-delay: 999s, 999s, 999s;
        }
    }

    @keyframes slide-in7 {
        100% {
            right: 5vmin;
        }
    }

    @keyframes slide-top-half7 {
        100% {
            width: calc( 100vw - 10vmin );
            height: calc( ( 100vh - 15vmin ) / 3 );
            height: calc( ( 100svh - 15vmin ) / 3 );
        }
    }

    @keyframes slide-out7 {
        100% {
            top: calc( ( 100vh - 15vmin ) / -3 );
            top: calc( ( 100svh - 15vmin ) / -3 );
        }
    }
`

const VideoContainer8 = styled.div`
    width: calc( 100vw - 10vmin );
    height: calc( ( 100vh - 15vmin ) / 3 * 2 );
    height: calc( ( 100svh - 15vmin ) / 3 * 2 );
    position: absolute;
    overflow: hidden;
    right: -100vw;
    top: 100vh;
    top: 100svh;
    border-radius: 40px;
    &.run-animation {
        animation:  slide-in8 ${ANIMATION_MOVE}ms forwards,
                    slide-bottom-left-half8 ${ANIMATION_MOVE}ms forwards,
                    slide-out8 ${ANIMATION_MOVE}ms forwards;

        animation-delay: ${6*ANIMATION_LENGTH}ms, ${7*ANIMATION_LENGTH}ms, ${8*ANIMATION_LENGTH}ms;
        &.disable_out_1n2,
        &.disable_in_3 {
            animation-delay: 999s, 999s, 999s;
        }
    }

    @keyframes slide-in8 {
        100% {
            right: 5vmin;
            top: calc( ( ( 100vh - 15vmin ) / 3 ) + 10vmin );
            top: calc( ( ( 100svh - 15vmin ) / 3 ) + 10vmin );
        }
    }

    @keyframes slide-bottom-left-half8 {
        100% {
            width: calc( ( 100vw - 15vmin) / 2 );
            right: calc( ( ( 100vw - 15vmin) / 2 ) + 10vmin );
        }
    }

    @keyframes slide-out8 {
        100% {
            top: 100vh;
            top: 100svh;
        }
    }
`

const VideoContainer9 = styled.div`
    width: calc( ( 100vw - 15vmin) / 2 );
    height: calc( ( 100vh - 15vmin ) / 3 * 2 );
    height: calc( ( 100svh - 15vmin ) / 3 * 2 );
    position: absolute;
    overflow: hidden;
    right: calc( ( 100vw - 15vmin) / -2 );
    top: calc( ( ( 100vh - 15vmin ) / 3 ) + 10vmin );
    top: calc( ( ( 100svh - 15vmin ) / 3 ) + 10vmin );
    border-radius: 40px;
    &.run-animation {
        animation:  slide-in9 ${ANIMATION_MOVE}ms forwards,
                    slide-out9 ${ANIMATION_MOVE}ms forwards;

        animation-delay: 49s, 56s;
        animation-delay: ${7*ANIMATION_LENGTH}ms, ${8*ANIMATION_LENGTH}ms;
        &.disable_out_1n2,
        &.disable_in_3 {
            animation-delay: 999s, 999s;
        }
    }

    @keyframes slide-in9 {
        100% {
            right: 5vmin;
        }
    }

    @keyframes slide-out9 {
        100% {
            top: 100vh;
            top: 100svh;
        }
    }
`

const SReactPlayer = styled(ReactPlayer)`
    width: 100%!important;
    height: 100%!important;
    z-index: 0;
    video {
        /* width: 100%!important;
        height: 100%!important;  */
        /* position: absolute;
        left: 50%; // % of surrounding element
        top: 50%;
        transform: translate(-50%, -50%); // of current element  */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        z-index: -100;
    }
`

var interval;
var firstRender = true;

const animationTimer = {
    0: [1, 2],
    1: [3],
    2: [3, 4, 5],
    3: [4, 5],
    4: [4, 5, 6],
    5: [4, 6, 7],
    6: [7, 8],
    7: [7, 8, 9],
    8: [3],
    9: [3],
    10: [3],
    11: [3],
    12: [3],
    13: [3],
};

function FlyingVideos(props) {
    const [transparent, set_transparent] = useState(false);
    const [runAnimation, set_runAnimation] = useState(false);
    const [animationStartEpoch, set_animationStartEpoch] = useState(0);
    const [disableEntryOfAnimation, set_disableEntryOfAnimation] = useState('');
    const [videos, set_videos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
           try {
            const response = await fetch(`${WP_API_URL}pages/86?acf_format=standard`);
            const newData = await response.json();
            const keys = Object.keys(newData.acf);
            let array = [];
            keys.forEach(key=>
               array.push(newData.acf[key])
            )
            set_videos(array);
            console.log('Videos for intro:', array);
           } catch (e) {
            console.log(e);
           }

        };
        if (videos.length === 0) fetchData();
    }, []);

    useEffect(() => {
        const startAnimation = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            set_runAnimation(true);
            set_animationStartEpoch(Date.now());
            interval = setInterval(async() => {
                set_runAnimation(false);
                set_animationStartEpoch(Date.now());
                await new Promise(resolve => setTimeout(resolve, 200));
                set_runAnimation(true);
            }, 8*ANIMATION_LENGTH + ANIMATION_MOVE);
        }
        startAnimation();
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if(props.stopIntro) {
            let timeInAnimation =  Date.now() - animationStartEpoch;
            timeInAnimation = timeInAnimation / 1000;
            timeInAnimation = Math.floor(timeInAnimation);

            if(Object.keys(animationTimer).includes(`${timeInAnimation+1}`)) {
                const animationNow = animationTimer[timeInAnimation];
                const animationNext = animationTimer[timeInAnimation+1];

                if(JSON.stringify(animationNow) === JSON.stringify(animationNext)) set_disableEntryOfAnimation(`disable_out_${JSON.stringify(animationNext).replaceAll(',','n').replace('[','').replace(']','')}`);
                else set_disableEntryOfAnimation(`disable_in_${JSON.stringify(animationNext).replaceAll(',','n').replace('[','').replace(']','')}`);
            }
           // setTimeout(()=>{set_transparent(true)}, props.titleAnimationTime);
        }
    }, [props.stopIntro]);


    return (
        <Container
            className={`FlyingVideos${props.stopIntro ? ' stopIntro' : ''}${transparent ? ' transparent' : ''}`}
            transparentAnimationTime={props.transparentAnimationTime}
        >
            <ContainerTransparent
                className={`ContainerTransparent ${props.stopIntro ? 'stopIntro' : ''}`}
            >
                <VideoContainer1
                    className={["VideoContainer1", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
            //        style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[0]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer1>

                <VideoContainer2
                    className={["VideoContainer1", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
           //         style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[1]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer2>

                <VideoContainer3
                    className={["VideoContainer3", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
             //       style={{display: 'none'}}
                >
                        <SReactPlayer
                            url={videos[2]}
                            muted
                            autoPlay
                        //    controls
                            loop
                            preload="auto"
                            playing
                            webkit-playsinline
                            playsinline
                        />
                </VideoContainer3>

                <VideoContainer4
                    className={["VideoContainer4", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
           //         style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[3]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer4>

                <VideoContainer5
                    className={["VideoContainer5", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
              //      style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[4]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer5>

                <VideoContainer6
                    className={["VideoContainer6", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
             //       style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[5]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer6>


                <VideoContainer7
                    className={["VideoContainer7", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
             //       style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[6]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer7>

                <VideoContainer8
                    className={["VideoContainer8", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
            //        style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[7]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer8>

                <VideoContainer9
                    className={["VideoContainer9", runAnimation && 'run-animation', disableEntryOfAnimation].join(' ')}
            //        style={{display: 'none'}}
                >
                    <SReactPlayer
                        url={videos[8]}
                        muted
                        autoPlay
                    //    controls
                        loop
                        preload="auto"
                        playing
                        webkit-playsinline
                        playsinline
                    />
                </VideoContainer9>

            </ContainerTransparent>
        </Container>
    );
  }

  export default FlyingVideos;
