import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import Slider from '@mui/material/Slider';

const StyledSlider = styled(Slider)`
    &.slider-zoom{
        position: absolute;
    bottom: 8px;
    right: 24px;
    width: 300px;
    }

`

function ZoomSlider (props){
  return (
    <StyledSlider
        className="slider-zoom"
        value={props.value}
        aria-label="Zoom"
        onChange={props.onChange}
    //    valueLabelDisplay="auto"
    />
  );
}

export default ZoomSlider;

