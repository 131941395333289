import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import FlyingVideos from "../Intro/FlyingVideos";
import Title from "../Intro/Title";
import Header from "../Header";
import Morphing from '../Morphing';
import Chapters from '../Chapters'
import Chapter from '../../pages/Chapter'
import Intro from '../Intro'


const TranstionComponent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 40px;
  width: 100%;
  background-color: white;
`

function Layout() {
  return (
    <>
      <Intro/>
      <Header/>
      <div
        className='Outlet'
        id='Outlet'
      >
        <Outlet/>
        <TranstionComponent>
          <div id='TranstionComponent'/>
        </TranstionComponent>
      </div>
      <div style={{marginBottom: '64px', width: '1px', height: '1px'}}/>
    </>
  );
}

export default Layout;
