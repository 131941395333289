import React, { useState, useEffect } from 'react';
import { WP_API_URL } from '../../config';
import styled from "@emotion/styled";
import Paragraph from '../Paragraph'
import BookInfo from '../BookInfo'

const Container = styled.div`
    display: grid;
    justify-content: center;
    /* gap: 16px; */
    justify-items: center;
    margin: auto;
    margin-top: 48px;
    margin-bottom: 100px;
    max-width: 768px;
    font-size: 24px;

    .expandable-container {
        button {
            /* @media screen and (max-width: 1050px){
                top: 40px;
            } */
            margin-top: -15px;
        }
        span.expandable {
            margin-top: 80px;
        }
        .book-review {
            margin-top: 40px;
        }
    }

`

const MorphingParagraph = styled(Paragraph)`
    font-size: 18px;


`


const Header3 = styled.h3`
    font-size: 60px;
    text-align: center;
    @media screen and ( max-width: 400px ) {
        font-size: 30px;
    }
`

function Morphing(props) {
    return (
        <Container
            className={`MorphingSection `}
        >
            <Header3>
                {props.morphing.title}
            </Header3>
            <Paragraph
                dangerouslySetInnerHTML={{__html: props.morphing.content}}
                bottomContent={
                    <>
                        <BookInfo
                            coverUrl={props.bookInfo.coverUrl}
                            content={props.bookInfo.content}
                            pdfUrl={props.bookInfo.pdfUrl}
                            orderUrl={props.bookInfo.orderUrl}
                        />
                        <div
                            dangerouslySetInnerHTML={{__html: props.bookInfo.review}}
                            className={`book-review `}
                        />
                    </>
                }
                noOverflowHidden
            />
        </Container>
    );
}

export default Morphing;
