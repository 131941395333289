import React, { useState, useEffect } from 'react';
import { WP_API_URL } from '../../config';
import styled from "@emotion/styled";
import { Link, useParams, useLoaderData } from "react-router-dom";

const SLink = styled(Link)`
    text-decoration: none;
    color: black;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 366px;
    cursor: pointer;
    .ImageContainer{
        text-align: center;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        border-radius: 18px;
        transition: all 2s ease-in;
        overflow: hidden;
    }
`

const Image = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
    background: darkgray;
    ${props => props.imageUrl ? `background-image: url(${props.imageUrl});` : ""};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 18px;
    transition: all 0.5s ease-in;
    &:hover {
        transform: scale(1.07);
    }
`

const Title = styled.div`
    margin-top: 6px;
    margin-bottom: 4px;
    font-size: 15px;
    max-height: 34px;
    height: 100%;
`

function Thumbnail(props) {
    return (
        <SLink
            to={`/chapter/${props.postId}`}
        >
            <Container 
                className={`Thumbnail `}
            >   
                <div
                    className={`ImageContainer `}
                >
                    <Image
                        imageUrl={props.imageUrl}
                        className={`Image `}
                    />
                </div>
                <Title className={`Title `}>
                    {props.title}
                </Title>
            </Container>
        </SLink>
    );
  }
  
  export default Thumbnail;
  