import React, { useState, useEffect } from 'react';
import FlyingVideos from "./FlyingVideos";
import Title from "./Title";
import MovingBackground from './MovingBackground';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';


const TITLE_ANIMATION_TIME = 3000;
const TRANSPARENT_ANIMATION_TIME = 500;

const Container = styled.div`
  width: 100vw;
  width: 100%; // scroll into account
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  transition:visibility ${TRANSPARENT_ANIMATION_TIME}ms linear,opacity ${TRANSPARENT_ANIMATION_TIME}ms linear;
  z-index: 10;
  &.transparent {
    visibility:hidden;
    opacity:0;
  }

`

function Intro() {
  const [stopIntro, set_stopIntro] = useState(false);
  const [visible, set_visible] = useState(true);
  const [transparent, set_transparent] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if(stopIntro) {
      setTimeout(()=>{set_transparent(true)}, TITLE_ANIMATION_TIME);
    }
  }, [stopIntro]);

  useEffect(() => {
    if(pathname !== '/') set_visible(false);
  }, []);

  const startWebsite = () => {
    set_stopIntro(true);
    setTimeout(()=>{set_visible(false)}, TRANSPARENT_ANIMATION_TIME+TITLE_ANIMATION_TIME+100);
  }

  const css = `
    .HomePage {
      height: 0px;
    }
  `

  const cssStopping = `
    .HomePage {
      height: calc( 100vh - 64px - 32px - 64px );
    }
  `

  return (
    <>
      {
        visible && pathname === '/' &&
        <Container 
          className={`Intro${transparent ? ' transparent' : ''}`}
        >
          <MovingBackground/>
          <Title
            onClick={startWebsite}
            titleAnimationTime={TITLE_ANIMATION_TIME}
          />
          <FlyingVideos
            stopIntro={stopIntro}
            titleAnimationTime={TITLE_ANIMATION_TIME}
          />
          <style>
            {!stopIntro && css}
            {stopIntro && cssStopping}
          </style>
        </Container>
      }
    </>
  );
}

export default Intro;
