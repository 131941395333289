import React, { useState, useEffect } from 'react';
import Chrome from '../../components/Intro/Chrome.svg'
import { WP_API_URL } from '../../config';
import styled from "@emotion/styled";
import MovingBackground from './MovingBackground';

const css = `
  // html {
  //   background-image: url(${Chrome});
  //   background-size: cover;
  // }
`

const Content = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ChapterMediaTitle {
    font-size: 15px;
    text-align: center;
    max-width: 885px;
    margin-bottom: 100px;
  }

  .address {
    display: block;
    font-size: 15px;
    line-height: 18px;
    white-space: pre-line;
    min-width: 190px;
    &.address-below {
      margin-top: 32px;
      display: none;
      @media screen and ( max-width: 1500px ) {
        display: block;
      }
    }
  }
`

const ContainerForText = styled.div`
  position: relative;
`

const Typography = styled.div`
  max-width: 885px;
  width: 100%;
  margin: auto;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  p {
    margin-bottom: 16px;
  }
  &.about-copy{
    text-align: justify;
    p {
      line-height: 34px;
    }
  }
  &.legal {
    margin-top: 40px;
    display: block;
    font-size: 15px;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 100px;
    line-height: 18px;
  }
`


const Image = styled.img`
  margin: 16px;
  margin-top: 100px;
  max-width: 600px;
  width: 100%;
  border-radius: 18px;
`

const SplitAbout = styled.div`
  width: 100%;
  max-width: 885px;
  gap: 64px;
  white-space: pre-line;
  position: relative;
  margin-bottom: 100px;
  .left {
    display: block;
    margin: 0;
    font-size: 15px;
    width: unset;
    max-width: unset;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 100px;
    line-height: 18px;
  }
  .right.address {
    position: absolute;
    display: block;
    margin: 0;
    min-width: 190px;
    width: unset;
    max-width: unset;
    position: absolute;
    top: 0px;
    right: -275px;
    @media screen and ( max-width: 1500px ) {
      display: none;
    }
  }
  @media screen and ( max-width: 600px ) {
    flex-direction: column;
  }
`

const SplitAbout2 = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 885px;
  gap: 32px;
  white-space: pre-line;
  font-size: 15px;
  margin-top: 32px;
  .left {
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }
  .right {
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }
  @media screen and ( max-width: 600px ) {
    flex-direction: column;
  }
`


const Contact = styled.div`
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  white-space: pre-line;
  margin-bottom: 32px;
  text-align: left;
  a {
    color: black!important;
  }
  @media screen and ( min-width: 1365px ) {
    position: absolute;
    top: 6px;
    right: -225px;
    max-width: 160px;
  }
`


function About(props) {
  const [content, set_content] = useState(null);
  const [performances, set_performances] = useState(null);
  const [address, set_address] = useState(null);
  const [mediaId, set_mediaId] = useState(null);
  const [imageUrl, set_imageUrl] = useState(null);
  const [contact, set_contact] = useState(null);
  const [caption, set_caption] = useState(null);
  const [grid1, set_grid1] = useState(null);
  const [grid2, set_grid2] = useState(null);
  const [grid3, set_grid3] = useState(null);
  const [grid4, set_grid4] = useState(null);
  const [legal, set_legal] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
       try {
        const newData = props.content;
        set_mediaId(newData.acf.image);
        set_mediaId(newData.acf.image);
        set_content(newData.content.rendered);
        set_performances(newData.acf.performances);
        set_address(newData.acf.adresse);
        set_contact(newData.acf.contact);
        set_caption(newData?.acf?.caption);
        set_grid1(newData?.acf?.grid_1);
        set_grid2(newData?.acf?.grid_2);
        set_grid3(newData?.acf?.grid_3);
        set_grid4(newData?.acf?.grid_4);
        set_legal(newData?.acf?.legal);
       } catch (e) {
        console.log(e);
       }
    };
    if (props.content && !content) fetchData();
}, [props.content]);

useEffect(() => {
  const fetchData = async () => {
     try {
      const response = await fetch(`${WP_API_URL}media/${mediaId}`);
      const newData = await response.json();
      console.log(newData);
      set_imageUrl(newData.source_url);
     } catch (e) {
      console.log(e);
     }
  };
  if (!imageUrl && mediaId) fetchData();
}, [mediaId]);

  return (
    <Content className="About">
      <MovingBackground/>
      <ContainerForText>
        <Contact
          dangerouslySetInnerHTML={{__html: contact}}
        />
        <Typography
          className="about-copy"
          dangerouslySetInnerHTML={{__html: content}}
        />
      </ContainerForText>

      <Image src={imageUrl}/>
      {
          caption  &&
          <p
              className={`ChapterMediaTitle`}
              dangerouslySetInnerHTML={{ __html: caption }}
          />
      }

      <SplitAbout>
        <Typography
          className='left'
          dangerouslySetInnerHTML={{__html: performances}}
        />

        <Typography
          className='right address'
          dangerouslySetInnerHTML={{__html: address}}
        />
      </SplitAbout>

      <SplitAbout2
        className='bio-grid'
      >
        <Typography
          className='left'
          dangerouslySetInnerHTML={{__html: grid1}}
        />

        <Typography
          className='right'
          dangerouslySetInnerHTML={{__html: grid2}}
        />
      </SplitAbout2>
      <SplitAbout2
        className='bio-grid'
      >
        <Typography
          className='left'
          dangerouslySetInnerHTML={{__html: grid3}}
        />

        <Typography
          className='right'
          dangerouslySetInnerHTML={{__html: grid4}}
        />
      </SplitAbout2>

      <Typography
        className="legal"
        dangerouslySetInnerHTML={{__html: legal}}
      />

      <Typography
        className='address address-below'
        dangerouslySetInnerHTML={{__html: address}}
      />

      <style>
        {css}
      </style>
    </Content>
  );
}

export default About;
