import React, { useState, useEffect, useRef } from 'react';
import styled from "@emotion/styled";
import MuiButton from '@mui/material/Button';
import { Link, useParams, useLoaderData, useNavigate  } from "react-router-dom";

const Container = styled.div`
    position: relative;
    max-width: 768px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-top: 100px;
    .book-info-cover {
        object-fit: contain;
        width: 50%;
    }
    .book-info-content {
        p {
            font-size: 15px!important;
            line-height: 18px!important;
            text-align: left;
        }
    }
    @media screen and ( max-width: 400px ) { 
        flex-direction: column;
        .book-info-cover {
            width: 100%;
        }
    }

`
const Button = styled(MuiButton)`
    font-family: 'Alpha Font', monospace;
    border-radius: 24px;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 9px;
    height: 33px;
    margin-top: 0!important;
    position: absolute;
    width: 86px;
    &.book-info-order{
        top: 0px;
        right: -119px;
    }
    &.book-info-pdf{
        top: 40px;
        right: -119px;
    }

    &:hover {
        border: 1px solid #000000;
    }

    @media screen and ( max-width: 1050px ) { 
        &.book-info-order{
            top: -50px;
            right: -5px;
        }
        &.book-info-pdf{
            top: -50px;
            right: 100px;
        }
    }
`

function BookInfo(props) {
    console.log('BookInfo(props)', props)
    return (
        <Container
            className={`book-info `}
            id={`paragraph-${props.index}`}
        >
            <Button
                variant="outlined"
                className={`book-info-order `}
                href={props.orderUrl}
                target='_blank'
            >
                ORDER
            </Button>
            <Button
                variant="outlined"
                className={`book-info-pdf `}
                href={props.pdfUrl}
                target='_blank'
            >
                PDF
            </Button>
            <img
                src={props.coverUrl}
                className={`book-info-cover `}
            />
            <span
                dangerouslySetInnerHTML={{__html: props.content}}
                className={`book-info-content `}
            />
        </Container>
    );
  }
  
  export default BookInfo;
  