import React, { useState } from 'react';
import Morphing from '../../components/Morphing';
import Chapters from '../../components/Chapters'
import YellowBanner from '../../components/YellowBanner';
import MovingBackground from '../About/MovingBackground';

function HomePage(props) {
  const [showIntro, set_showIntro] = useState(true);
  const [stopIntro, set_stopIntro] = useState(false);


  const startWebsite = () => {
    set_stopIntro(true);
  }

  return (
    <div className="HomePage">
      {/* <MovingBackground/> */}
      <YellowBanner
        yellowBanner={props.yellowBanner}
      />
      <Morphing
        morphing={props.morphing}
        bookInfo={props.bookInfo}
      /> 
      <Chapters
        posts={props.posts} 
      />
    </div>
  );
}

export default HomePage;
