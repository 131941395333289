import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";



const Container = styled.div`
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color: #282c34;
    z-index: -1;
    overflow: hidden;
`

function MovingBackground(props) {

    const Background0 = styled.div`
        width: 100vw;
        height: 100vh;
        color: #fff;
        background: linear-gradient(0deg, #A0A0A0, #eeeeee, #696969, #aaaaaa, #c4a4a4, #A19090, #e9e9e9);
        background-size: 400% 400%;
        animation: Gradient 40s ease infinite;

        @keyframes Gradient {
            0% {
                background-position: 50% 0% 
            }
            50% {
                background-position: 50% 100% 
            }
            100% {
                background-position: 50% 0% 
            }
        }
    `

    

    return (
        <Container 
            className={`MovingBackground`}
        >
            <Background0 />
        </Container>
    );
  }
  
  export default MovingBackground;
  