import React, { useState, useEffect } from 'react';
import { WP_API_URL } from '../../config';
import styled from "@emotion/styled";
import { useParams, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import GrayBar from './GrayBar'
import ContentOfChapter from './ContentOfChapter';

const Container = styled.div`
    font-size: 24px;
    p {
        margin: auto;
        max-width: 768px;
        font-size: 24px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SliderContainer = styled.div`
    width: 300%;
    display: flex;
    flex-direction: row;
    > div {
        flex: 1;
        width: calc( 100% / 3 );
    }

    &.movingLeft {
        margin-left: 200%;
        transition: all 1s ease;
    }
    &.movingRight {
        margin-right: 200%;
        transition: all 1s ease;
    }
    &.transition {
        transition: all 1s ease;
    }

`

const css = `
    .HeaderContainer .Menu {
        display: none;
    }
    .App {
        padding: 0!important;
    }
`

function Chapter(props) {
    const navigate = useNavigate();
    const [title, set_title] = useState('');
    const [post, set_post] = useState(null);
    const [html, set_html] = useState(null);
    const [parsedHTML, set_parsedHTML] = useState([]);
    const [forwardsTitle, set_forwardsTitle] = useState(null);
    const [backwardsTitle, set_backwardsTitle] = useState(null);
    const [forwardsId, set_forwardsId] = useState(null);
    const [backwardsId, set_backwardsId] = useState(null);
    const { id } = useParams();
    const [offsetY, set_offsetY] = useState(0);
    const [bodyHeight, set_bodyHeight] = useState(0);
    const [windowHeight, set_windowHeight] = useState(window.innerHeight);
    const [windowWidth, set_windowWidth] = useState(window.innerWidth);

    const [movingRight, set_movingRight] = useState(false);
    const [movingLeft, set_movingLeft] = useState(false);
    const [movingAnimation, set_movingAnimation] = useState(false);

    useEffect(() => {
        const index = props.posts ? props.posts.findIndex(elem => elem.id == id) : -1;

        if(index === -1 ) return;

        if(index > 0) {
            const backIndex = index - 1;
        //    console.log('backIndex', backIndex)
            set_backwardsTitle(props.posts[parseInt(index)-1]?.title?.rendered);
            set_backwardsId(props.posts[parseInt(index)-1]?.id);
        } else if (index === 0) {
            set_backwardsTitle(null);
            set_backwardsId(null);
        }

        if(index < props.posts.length-1) {
            const forwardIndex = index + 1;
        //    console.log('forwardIndex', forwardIndex)
            set_forwardsTitle(props.posts[parseInt(index)+1]?.title?.rendered);
            set_forwardsId(props.posts[parseInt(index)+1]?.id);
        } else if (index === props.posts.length-1 ) {
            set_forwardsTitle(null);
            set_forwardsId(null);
        }

    }, [props.posts, id]);

    useEffect(() => {
        const onWindoResize = () => {
            set_windowHeight(window.innerHeight);
            set_windowWidth(window.innerWidth);
        }
        window.removeEventListener('resize', onWindoResize);
        window.addEventListener('resize', onWindoResize, { passive: true });
        return () => {
            window.removeEventListener('resize', onWindoResize);
        }
    }, []);

    const moveRight = (id) => {
        set_movingAnimation(true);
        set_movingRight(true);
        set_movingLeft(false);
        const outlet = document.getElementById('TranstionComponent')
        const chapter = document.getElementById(`ContentOfChapter_${id}`)
        let chapterClonded = chapter.cloneNode(true);
        chapterClonded.id = `ContentOfChapter_${id}B`;
        chapterClonded.classList.add('transition-content-of-chapter');
        setTimeout(()=> {
            outlet.append(chapterClonded);
            setTimeout(()=> {
                navigate(`/chapter/${id}`);
                set_movingRight(false);
                setTimeout(()=> {
                    document.getElementById(`ContentOfChapter_${id}B`).remove();
                    set_movingAnimation(false);
                }, 100);
            }, 100);
        }, 1050);
    }

    const moveLeft = (id) => {
        set_movingAnimation(true);
        set_movingRight(false);
        set_movingLeft(true);
        const outlet = document.getElementById('TranstionComponent')
        const chapter = document.getElementById(`ContentOfChapter_${id}`)
        let chapterClonded = chapter.cloneNode(true);
        chapterClonded.id = `ContentOfChapter_${id}B`;
        chapterClonded.classList.add('transition-content-of-chapter');
        setTimeout(()=> {
            outlet.append(chapterClonded);
            setTimeout(()=> {
                navigate(`/chapter/${id}`);
                set_movingLeft(false);
                setTimeout(()=> {
                    document.getElementById(`ContentOfChapter_${id}B`).remove();
                    set_movingAnimation(false);
                }, 100);
             }, 100);
        }, 1050);
    }

    return (
        <Container
            className={`Chapter `}
            id={props.id}
        >
            <GrayBar
                title={title}
                backwardsId={backwardsId}
                backwardsTitle={backwardsTitle}
                forwardsId={forwardsId}
                forwardsTitle={forwardsTitle}
                windowWidth={windowWidth}
                moveLeft={moveLeft}
                moveRight={moveRight}
                movingAnimation={movingAnimation}
            />
            <style>
                {css}
            </style>
            <SliderContainer
                className={`SliderContainer
                ${movingRight ? 'movingRight' : ''}
                ${movingLeft ? 'movingLeft' : ''}
                `}
            >
                {
                    backwardsId ?
                    <ContentOfChapter
                        slide={1}
                        chapterId={backwardsId}
                        key={`ContentOfChapter_0_${backwardsId}`}
                        id={`ContentOfChapter_${backwardsId}`}
                        title={title}
                        windowWidth={windowWidth}
                        windowHeight={windowHeight}
                        posts={props.posts}
                        set_postsContent={props.set_postsContent}
                        postsContent={props.postsContent}
                        postsContentIds={props.postsContentIds}
                        fetchChapter={props.fetchChapter}
                    />
                    :
                    <div/>
                }
                    <ContentOfChapter
                        slide={2}
                        chapterId={id}
                        key={`ContentOfChapter_1_${id}`}
                        id={`ContentOfChapter_${id}`}
                        title={title}
                        windowWidth={windowWidth}
                        windowHeight={windowHeight}
                        posts={props.posts}
                        set_postsContent={props.set_postsContent}
                        postsContent={props.postsContent}
                        postsContentIds={props.postsContentIds}
                        fetchChapter={props.fetchChapter}
                        visibleChapter
                    />
                {
                    forwardsId ?
                    <ContentOfChapter
                        slide={3}
                        chapterId={forwardsId}
                        key={`ContentOfChapter_2_${forwardsId}`}
                        id={`ContentOfChapter_${forwardsId}`}
                        title={title}
                        windowWidth={windowWidth}
                        windowHeight={windowHeight}
                        posts={props.posts}
                        set_postsContent={props.set_postsContent}
                        postsContent={props.postsContent}
                        postsContentIds={props.postsContentIds}
                        fetchChapter={props.fetchChapter}
                    />
                    :
                    <div/>
                }
            </SliderContainer>
        </Container>
    );
  }

  export default Chapter;
