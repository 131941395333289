import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import Chrome from './Chrome.svg'

const Container = styled.div`
    position: absolute;
    top: 0;
    top: -50%;
    left:0;
    width: 100vw;
    height: 100vh;
    height: 200vh;
    background-color: #282c34;
    z-index: 1;
    overflow: hidden;
`

function MovingBackground(props) {

    const Background0 = styled.div`
        width: 100vw;
        height: 100vh;
        height: 200vh;
        color: #fff;
        background: linear-gradient(0deg, #A0A0A0, #eeeeee, #696969, #aaaaaa, #c4a4a4, #A19090, #e9e9e9);
        background-size: 400% 400%;
        animation: Gradient 80s ease infinite;

        @keyframes Gradient {
            0% {
                background-position: 50% 0%
            }
            50% {
                background-position: 50% 100%
            }
            100% {
                background-position: 50% 0%
            }
        }
    `



    return (
        <Container
            className={`MovingBackground`}
        >
            <Background0 />
        </Container>
    );
  }

  export default MovingBackground;
