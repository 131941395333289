import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import Chart from './chart';
import Card from './card';
import MovingBackground from '../About/MovingBackground'
import ZoomPopup from '../../components/ZoomPopup';
import { data } from './data'



const Content = styled.div`
  p {
    display: block;
    font-size: 15px;
    line-height: 18px;
    white-space: pre-line;
    min-width: 190px;
    min-height: 25px;
  }
`;


function ChartPage() {
  return (
    <Content
      className="chat-subpage-content"
    >
      <MovingBackground />
      <OnTheBackground/>
    </Content>
  );
}

function OnTheBackground() {
  const [chosenNode, set_chosenNode] = useState(null);

  function node(id) {
    set_chosenNode(id);
  }

  return (
    <>
      <div
        onClick={() => { set_chosenNode(null) }}
      >
        <Chart
          data={data}
          onHover={node}
          chosenNode={chosenNode}
        />
      </div>

      <Card
        uuid={chosenNode}
      //  onClose={() => { set_chosenNode(null) }}
      />

      <ZoomPopup/>
    </>
  );
}

export default ChartPage;

