import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import Zoom from './3-zoom-hands.svg'
import animation1 from './click.json';
import animation2 from './zoom.json';
import animation3 from './drag.json';
import { useLocation } from 'react-router-dom';
import lottie from 'lottie-web';

const Container = styled.div`
  width: 100vw;
  width: 100%; // scroll into account
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeIn 2s;
  background-color: #303030e3;
  z-index: 100;

  &.fadeOut {
    animation: fadeOut 0.5s;
    opacity: 0;
  }
  &.out {
    display: none;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  .close-text {
    font-family: Arial;
    font-size: 20px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.5625px;
    text-align: center;
    color: white;
    position: fixed;
    pointer-events: none;
    bottom: 30px;
    @media screen and ( max-height: 360px ){
      bottom: 10px;
    }
  }
`


const Popup = styled.div`
  max-width: 100%;
  max-height: 100%;
  @media screen and ( max-height: 360px ){
    max-height: calc( 100% - 60px );
  }
  width: 100%;
  height: 100%;
  /* background: url(${Zoom}); */
  /* background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  @media screen and ( max-width: 600px ){
    gap: 16px;
  }
  > div {
    color: white;
    text-align: center;
    margin-top: 16px;
    font-size: 25px;
    @media screen and ( max-width: 600px ){
      font-size: 5vw;
    }
    > div {
      width: 160px;
      @media screen and ( max-width: 600px ){
        width: 25vw;
      }
      > svg {
        max-height: 50vh;
      }
    }
  }
`

function ZoomPopup() {
  const [stop, set_stop] = useState(false);
  const [fadeout, set_fadeout] = useState(false);
  const animationLoaded = useRef(false);

  useEffect(() => {
    // check to prevent double animation load on page remount
    if (!animationLoaded.current) {

      lottie.loadAnimation({
        container: document.querySelector(`.animation-1`),
        animationData: animation1,
      });
      lottie.loadAnimation({
        container: document.querySelector(`.animation-2`),
        animationData: animation2,
      });
      lottie.loadAnimation({
        container: document.querySelector(`.animation-3`),
        animationData: animation3,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    animationLoaded.current = true;
  }, []);

  return (
    <Container
      onClick={() => {
        setTimeout(() => { set_stop(true) }, 600)
        set_fadeout(true);
      }}
      className={
        `${stop ? 'out' : ''} ${fadeout ? 'fadeOut' : ''}`
      }
    >
      <Popup>
        <div>
          <div className='animation-1' />
          Click
        </div>
        <div>
          <div className='animation-3' />
          Drag
        </div>
        <div>
          <div className='animation-2' />
          Zoom
        </div>
      </Popup>
      <div className='close-text'>Click to close</div>
    </Container>
  );
}

export default ZoomPopup;
