import React, { useState, useEffect } from 'react';
import { WP_API_URL } from '../../config';
import styled from "@emotion/styled";
import { useParams, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import ChapterMedia from '../../components/Blocks/ChapterMedia';
import Paragraph from '../../components/Paragraph'

const Container = styled.div`
    font-size: 24px;
    p {
        margin: auto;
        max-width: 768px;
        font-size: 24px;
    }
    .padding-chapter {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
    }
    &.notVisibleChaper {
        height: 100vh;
        button {
            border-color: rgba(0, 0, 0, 0.3)!important;
        }
    }
    @media screen and ( max-width: 320px ) {
        word-break: break-word;
    }
`

const Header3 = styled.h3`
    margin: 0;
    margin-top: 200px;
    margin-bottom: 30px;
    font-size: 60px;
    width: 100%;
    max-width: 768px;
    text-align: center;
    min-height: 134px;
    @media screen and ( max-width: 400px ) {
        font-size: 30px;
    }
`

function ContentOfChapter(props) {
    const [title, set_title] = useState('');
    const [html, set_html] = useState(null);
    const [parsedHTML, set_parsedHTML] = useState([]);
    const [offsetY, set_offsetY] = useState(0);
    const [bodyHeight, set_bodyHeight] = useState(0);

    const id = props.chapterId;

    useEffect(() => {
        console.log('useEffect id', props.postsContent, id, props.slide);
        props.fetchChapter(id);
    }, [props.postsContent, id]);


    useEffect(() => {
        console.log('postsContentIds', props.postsContentIds, id);
        if (Object.keys(props.postsContent).includes(`${id}`)) {
            const newData = props.postsContent[id];
            set_html(newData?.content?.rendered);
            set_title(newData?.title?.rendered);
        }
    }, [props.postsContent, props.postsContentIds, id]);

    useEffect(() => {
      //  console.log('useEffect html', html)
        if (html?.length > 0) {
            let parsed = parse(html.replaceAll('\n', ''));
            if(parsed.length === undefined && typeof(parsed) === 'object') {
                set_parsedHTML([parsed]);
            } else {
                let groupParsed = [];
                let groupIndex = 0;
                let encounteredImg = true;

                for(let i = 0; i < parsed.length; i++){
                    const elem = parsed[i];
                    if(elem.props.className && (
                        elem.props.className.includes("data-payload-media-div") //||
              //          elem.props.className.includes("data-payload-title-in-chapter")
                    )) {
                        encounteredImg = true;
                        groupParsed.push(elem);
                    } else {
                        if(encounteredImg) {
                            groupParsed.push([elem]);
                            groupIndex = groupParsed.length-1;
                            encounteredImg = false;
                        } else {
                            groupParsed[groupIndex].push(elem);
                        }
                    }
                }
                console.log('groupParsed', groupParsed);

                let groupParsedToReact = [];
                for(let i = 0; i < groupParsed.length; i++){
                    const elem = groupParsed[i];
                    if(elem.props && elem.props.className && (
                        elem.props.className.includes("data-payload-media-div") //||
                 //       elem.props.className.includes("data-payload-title-in-chapter")
                    )) {
                        groupParsedToReact.push(elem);
                    } else  {
                        groupParsedToReact.push(React.createElement('div', { className: "paragraph-group" }, [elem]));
                    }
                }

                console.log('groupParsedToReact', groupParsedToReact);

                set_parsedHTML(groupParsedToReact);
            }
        } else {
            set_parsedHTML([]);
        }
    }, [html]);

    useEffect(() => {
        const onScroll = () => {
            set_offsetY(window.scrollY);
        }
        // const onWindoResize = () => {
        //     set_windowHeight(window.innerHeight);
        //     set_windowWidth(window.innerWidth);
        // }
        const observer = new ResizeObserver((entries) => {
            const rect = entries[0].contentRect;
            set_bodyHeight(rect.height);
        });
        window.removeEventListener('scroll', onScroll);
        //    window.removeEventListener('resize', onWindoResize);
        window.addEventListener('scroll', onScroll, { passive: true });
        //    window.addEventListener('resize', onWindoResize, { passive: true });
        observer.unobserve(document.documentElement);
        observer.observe(document.documentElement);
        return () => {
            window.removeEventListener('scroll', onScroll);
        //       window.removeEventListener('resize', onWindoResize);
            observer.unobserve(document.documentElement);
        }
    }, []);

    return (
        <Container
            className={`Chapter ${!props.visibleChapter ? 'notVisibleChaper' : 'VisibleChaper' }`}
            id={props.id}
        >
            <div
                className={`padding-chapter `}
            >
                <Header3>
                    {title}
                </Header3>
                {
                    parsedHTML && parsedHTML.length > 0 &&
                    parsedHTML.map((elem, index) => {
                        if(elem.type === 'p' && elem.props.children === null) return;
                        if(elem.props.className && elem.props.className.includes("data-payload-media-div")) return (
                            <ChapterMedia
                                key={`media-${id}-${index}`}
                                id={`media-${id}-${index}`}
                                {...elem.props }
                                offsetY={offsetY}
                                windowHeight={props.windowHeight}
                                windowWidth={props.windowWidth}
                                bodyHeight={bodyHeight}
                                log={index===1}
                                visibleChapter={props.visibleChapter}
                            />
                        )
                        return (
                            <Paragraph
                                key={`paragraph-${id}-${index}`}
                                id={`paragraph-${id}-${index}`}
                                index={index}
                                content={elem}
                            />
                        )
                    })
                }
            </div>
        </Container>
    );
  }

  export default ContentOfChapter;
