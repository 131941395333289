import React, { useState, useEffect, useRef } from 'react';
import styled from "@emotion/styled";
import MuiButton from '@mui/material/Button';

const Container = styled.span`
    font-size: 24px;
    position: relative;
    max-width: 768px;
    width: 100%;
    span.expandable {
        display: block;
        text-align: justify;
        height: 144px; //line-hegiht: 1.5
        height: 134px;
        overflow: hidden;
        margin-top: 100px;
	    transition: all 0.6s cubic-bezier(0.83, 0, 0.17, 1);
      //  transition: max-height 0.25s ease-in;
      //  transition: height 500ms;
        /* transform-origin: top;
        transform: scaleY(0);
        transform: scaleY(1) 0.6s cubic-bezier(0.83, 0, 0.17, 1);; */
        p {
            margin: auto;
            max-width: 768px;
            font-size: 24px;
            line-height: 1.5;
            /* margin-bottom: 16px; */
            @media screen and ( max-width: 600px ) {
                font-size: 18px;
//                line-height: 34px;
            }
        }
        &.expanded {
            height: ${props => props.height}px;
        }
        &.expanded.noOverflowHidden{
            overflow: unset;
        }
        .paragraph-group{
            p {
                /* margin-bottom: 16px; */
            }
        }
        h4{
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.938px;
            margin-bottom: 200px;
            margin-top: 200px;
        }
    }
    span.expandable.toGetHeight {
        position: fixed;
        bottom: 30000px;
        height: unset;
        padding-left: 8px;
    }

    @media screen and ( max-width: 701px ) {
        /* margin-bottom: 70px; */
    }

`
const Button = styled(MuiButton)`
    font-family: 'Alpha Font', monospace;
    border-radius: 24px;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 9px;
    height: 33px;

    position: absolute;
    right: -119px;
    top: 100px;

    &:hover {
        border: 1px solid #000000;
    }

    @media screen and ( max-width: 1050px ) {
        top: 50px;
        top: calc( 100% + 32px );
        right: 0px;
    }
`
const Elipsis = styled.div`
    font-family: 'Alpha Font', monospace;
    font-size: 24px;
    line-height: 34px;
    position: absolute;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.7) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
    height: 34px;
    width: 283px;
    bottom: 0px;
    right: 0;
    text-align: right;
`


function Paragraph(props) {
    const [expanded, set_expanded] = useState(false);
    const [showExpand, set_showExpand] = useState(false);
    const [clientHeight, set_clientHeight] = useState(false);
    const [overflowHack, set_overflowHack] = useState(false);
    const ref = useRef(null);
    const refToGetHeight = useRef(null);

    const refClientHeight = refToGetHeight?.current?.clientHeight + 34;

    useEffect(() => {
        const handleResize = () => {
            const height = refToGetHeight?.current?.clientHeight + 34;
            console.log('handleResize', height)
            set_clientHeight(height);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        console.log('refClientHeight', refClientHeight)
        set_clientHeight(refToGetHeight?.current?.clientHeight);
    }, [refClientHeight])


    useEffect(() => {
        const numOfChildren = ref?.current?.children.length;
        let height = 0;
        for(let i = 0; i < numOfChildren; i++) {
            const refHeight = ref?.current?.children[i]?.clientHeight;
            height = height + refHeight;
            if(height > 144) break;
        }
        set_showExpand(height > 144);
    }, [props.dangerouslySetInnerHTML, props.content])

    let timeoutHandle;

    const expand = () => {
        window.clearTimeout(timeoutHandle);
        if(expanded){
            set_overflowHack(false);
            set_expanded(false);
        } else {
            set_overflowHack(false);
            set_expanded(true)
            timeoutHandle = window.setTimeout(()=>{set_overflowHack(true)}, 600);
        }
    };

    return (
        <Container
            className={`expandable-container `}
            id={`paragraph-${props.index}`}
            height={clientHeight}
        >
            {
                showExpand &&
                <Button
                    variant="outlined"
                    onClick={expand}
                >
                    {expanded ? 'LESS' : 'MORE'}
                </Button>
            }
            <span
                className={`expandable toGetHeight ${props.bottomContent ? 'hasBottomContent' : ''} ${overflowHack && props.noOverflowHidden ? 'noOverflowHidden' : ''}`}
                ref={refToGetHeight}
            >
                <span
                    className={`InnerHTML`}
                    dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
                >
                {props.content}
                </span>
                {
                    props.bottomContent
                }
            </span>
            <span
                className={`expandable ${expanded || !showExpand ? 'expanded' : ''} ${props.bottomContent ? 'hasBottomContent' : ''} ${overflowHack && props.noOverflowHidden ? 'noOverflowHidden' : ''}`}
            >
                <span
                    ref={ref}
                    className={`InnerHTML`}
                    dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
                >
                {props.content}
                </span>
                {
                    (showExpand && expanded | !showExpand) ? props.bottomContent : null
                }
            </span>
            {!expanded && showExpand &&
                <Elipsis/>
            }

        </Container>
    );
  }

  export default Paragraph;
